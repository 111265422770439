const talentLabel = [
  { key: 1, value: '创业"和"伙人' },
  { key: 2, value: '创意"和"伙人' },
  { key: 3, value: '公益"和"伙人' },
  { key: 4, value: '共建"和"伙人' },
];
const modalList = [
  {
    key: "LV1",
    url: require("./img/modal1.png"),
  },
  {
    key: "LV2",
    url: require("./img/modal2.png"),
  },
  {
    key: "LV3",
    url: require("./img/modal3.png"),
  },
  {
    key: "LV4",
    url: require("./img/modal4.png"),
  },
  {
    key: "LV5",
    url: require("./img/modal5.png"),
  },
];
export { talentLabel, modalList };
