<template>
  <div class="applyTalent">
    <div class="step">
      <div class="step-l">
        <div class="item-t">1</div>
        <div class="item-b">填写申请</div>
      </div>
      <div
        :class="[
          'step-c',
          {
            active:
              form.auditStatus == 0 ||
              form.auditStatus == 1 ||
              form.auditStatus == 2,
          },
        ]"
      >
        <div class="item-t">2</div>
        <div class="item-b">审核中</div>
      </div>
      <div
        :class="[
          'step-r',
          { active: form.auditStatus == 1 || form.auditStatus == 2 },
        ]"
      >
        <template v-if="form.auditStatus == 2">
          <div class="item-t"><img src="./img/fail.png" alt="" /></div>
          <div class="item-b">申请失败</div>
        </template>
        <template v-else>
          <div class="item-t">
            <img
              v-if="form.auditStatus == 1"
              src="./img/finished.png"
              alt=""
            /><img v-else src="./img/toFinish.png" alt="" />
          </div>
          <div class="item-b">申请成功</div>
        </template>
      </div>
    </div>
    <div class="failReasons" v-if="form.auditStatus == 2">
      <div class="left"><img src="./img/fail.png" alt="" /></div>
      <div class="right">
        {{ form.auditRemarks }}
      </div>
    </div>
    <div class="talentType">
      <div class="talentType-l">达人类型</div>
      <div class="talentType-r">{{ form.talentType }}</div>
    </div>
    <div class="reasons">
      <div class="title">申请理由</div>
      <div class="reasonsBody">
        <v-input
          :disabled="form.auditStatus == 0 || form.auditStatus == 1"
          type="textarea"
          placeholder="请输入申请理由"
          :showWordLimit="true"
          :maxlength="200"
          v-model="form.talentContent"
        ></v-input>
      </div>
    </div>
    <div class="talentUpload">
      <div class="title">上传图片</div>
      <div class="upload">
        <v-upload
          :disabled="form.auditStatus == 0 || form.auditStatus == 1"
          :deletable="
            form.auditStatus == 0 || form.auditStatus == 1 ? false : true
          "
          :imgUrl.sync="form.talentPic1"
          :activityPicture.sync="form.talentPic"
          ref="load"
          :maxCount="6"
        ></v-upload>
      </div>
      <div class="tips">
        tips:您可以将自己的作品、证书等具备证明能力的物件以图片形式上传，增加审核通过的概率(最多6张)
      </div>
    </div>
    <div
      class="submit"
      @click="submit"
      v-if="
        form.auditStatus == 0 || form.auditStatus == 1 || form.auditStatus == 2
          ? false
          : true
      "
    >
      提交申请
    </div>
    <div class="submit" @click="submit" v-if="form.auditStatus == 2">
      重新申请
    </div>
  </div>
</template>

<script>
import { postTalentUrl, postTalentDetailUrl } from "./api.js";
import { talentLabel } from "./map.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "applyTalent",
  data() {
    return {
      form: {
        talentType: "",
        talentContent: "",
        talentPic: "",
        talentPic1: "",
      },
      id: "",
      postData: {},
      talentLabel,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.form.talentType = this.$route.query.talentType;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getApplyDetail();
    }
  },
  mounted() {
    // this.getPoints();
  },
  methods: {
    submit() {
      if (!this.form.talentContent) {
        this.$toast("理由不能为空");
        return;
      }
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      this.applyTalent();
    },
    getApplyDetail() {
      let params = {
        id: this.id,
      };
      this.$axios.get(`${postTalentDetailUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.form = res.data;
          this.form.talentPic1 = res.data.talentPic;
        } else {
        }
      });
    },
    applyTalent() {
      let params = {
        tenantId: this.tenantId,
        communityId: this.communityId,
        userId: this.userId,
        roomId: this.roomId,
        talentType: this.form.talentType,
        talentContent: this.form.talentContent,
        talentPic: this.form.talentPic,
      };
      this.$axios.post(`${postTalentUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast({ message: "申请成功", duration: 2000 });
          this.$router.replace({
            name: "talent",
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.applyTalent {
  min-height: 100vh;
  padding: 0 40px;
  .step {
    display: flex;
    padding: 64px 58px;
    justify-content: space-between;
    text-align: center;
    .step-l,
    .step-c {
      .item-t {
        margin: 0 auto 12px;
        width: 52px;
        height: 52px;
        font-size: 32px;
        line-height: 52px;
        background: rgba(0, 126, 255, 1);
        border-radius: 50%;
        color: #fff;
      }
      .item-b {
        color: #333;
        font-size: 28px;
      }
    }
    .step-c {
      position: relative;
      .item-t {
        background: #e3e3e3;
      }
      .item-b {
        color: #aaaaaa;
        font-size: 28px;
      }
      &::before {
        content: "";
        width: 140px;
        height: 1px;
        background: #e2e2e2;
        position: absolute;
        left: -140px;
        top: 26px;
      }
      &::after {
        content: "";
        width: 140px;
        height: 1px;
        background: #e2e2e2;
        position: absolute;
        right: -140px;
        top: 26px;
      }
    }
    .step-r {
      .item-t {
        margin: 0 auto 12px;
        width: 52px;
        height: 52px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        color: #aaaaaa;
        font-size: 28px;
      }
    }
    .active {
      .item-t {
        background: rgba(0, 126, 255, 1);
      }
      .item-b {
        color: #333;
      }
    }
    .step-r.active {
      .item-t {
        background: none;
      }
      .item-b {
        color: #333;
      }
    }
  }
  .failReasons {
    border: 1px solid #ff4758;
    background: rgba(255, 71, 88, 0.1);
    padding: 24px;
    margin: 0 0 32px;
    display: flex;
    border-radius: 20px;
    .left {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      font-size: 28px;
      line-height: 40px;
      color: #333;
      word-break: break-all;
    }
  }
  .talentType {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
    line-height: 44px;
    padding: 44px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #666;
  }
  .reasons {
    .title {
      font-size: 32px;
      margin: 32px 0;
      color: #666;
    }
    .reasonsBody {
      height: 320px;
    }
  }
  .talentUpload {
    .title {
      font-size: 32px;
      margin: 32px 0;
      color: #666;
    }
    .tips {
      color: #96979b;
      font-size: 24px;
      line-height: 32px;
      margin-top: 16px;
    }
  }
  .submit {
    width: 686px;
    height: 96px;
    background: rgba(0, 126, 255, 1);
    border-radius: 48px;
    text-align: center;
    color: #fff;
    font-size: 32px;
    line-height: 96px;
    margin: 120px auto;
  }
}
</style>
<style lang="less">
.applyTalent {
  .van-field {
    background: #f7f8fa;
    border-radius: 16px;
  }
}
</style>
